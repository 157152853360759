<template>
  <div>
    <v-app-bar clipped-left :class="headerColor" app>
      <span style="color:#ffffff;"><v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon></span>
      <v-toolbar-title><span style="color:#ffffff;">green サポートツール [{{ headerName }}]</span></v-toolbar-title>


      <div class="flex-grow-1"></div>

      <v-menu left bottom>
        <template v-slot:activator="{ props }">
          <v-btn icon="true" v-bind="props">
            <v-avatar color="indigo" size="36">
              <span class="white--text headline">{{ auth.getNameOneCharacter() }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="account-settings">
            <v-list-item-title>アカウント情報</v-list-item-title>
          </v-list-item>
          <v-list-item link="true" @click="logout">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-navigation-drawer
        style="position: fixed;"
        v-model="drawer"
        absolute
        width="300"
        temporary="true">

      <v-expansion-panels multiple v-model="panel" variant="accordion">
        <v-expansion-panel>
          <v-expansion-panel-title>
            {{ TOOL_CATEGORY_USER() }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                  v-for="row in menuUser"
                  :key="row.name"
                  v-show="auth.isOwnedPermission(row.permission)"
                  :to=row.to
              >
                <template v-slot:prepend>
                  <v-icon :icon="row.icon"></v-icon>
                </template>
                <v-list-item-title v-text="row.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title>
            {{ TOOL_CATEGORY_DATA() }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                  v-for="row in menuMaster"
                  :key="row.name"
                  v-show="auth.isOwnedPermission(row.permission)"
                  :to=row.to
              >
                <template v-slot:prepend>
                  <v-icon :icon="row.icon"></v-icon>
                </template>
                <v-list-item-title v-text="row.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title>
            {{ TOOL_CATEGORY_OPERATION() }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                  v-for="row in menuOperation"
                  :key="row.name"
                  v-show="auth.isOwnedPermission(row.permission)"
                  :to=row.to
              >
                <template v-slot:prepend>
                  <v-icon :icon="row.icon"></v-icon>
                </template>
                <v-list-item-title v-text="row.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-title>
            {{ TOOL_CATEGORY_TOOL() }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                  v-for="row in menuTool"
                  :key="row.name"
                  v-show="auth.isOwnedPermission(row.permission)"
                  :to=row.to
              >
                <template v-slot:prepend>
                  <v-icon :icon="row.icon"></v-icon>
                </template>
                <v-list-item-title v-text="row.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title>
            {{ TOOL_CATEGORY_DEBUG() }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-list density="compact">
              <v-list-item
                  v-for="row in menuDebug"
                  :key="row.name"
                  v-show="auth.isOwnedPermission(row.permission)"
                  :to=row.to
              >
                <template v-slot:prepend>
                  <v-icon :icon="row.icon"></v-icon>
                </template>
                <v-list-item-title v-text="row.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-text>
        </v-expansion-panel>

      </v-expansion-panels>

    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapMutations, mapGetters, mapActions} from 'vuex';
import * as Auth from '../config/auth';
import Permission, {P_MASK_VERSION_CONTROL} from '../config/permission';
import {
  TOOL_CATEGORY_DATA, TOOL_CATEGORY_DEBUG,
  TOOL_CATEGORY_OPERATION,
  TOOL_CATEGORY_TOOL,
  TOOL_CATEGORY_USER
} from "../config/tool_category";

export default {
  name: 'ToolMenu',
  data() {
    return {
      auth: null,
      drawer: false,
      panel: [0],
      menuUser: [
        {
          icon: 'mdi-card-account-details',
          name: 'ユーザー情報',
          to: {name: 'user'},
          permission: Permission.P_MASK_USER_SEARCH
        },
        {
          icon: 'mdi-math-log',
          name: 'アクションログ検索',
          to: {name: 'action-log'},
          permission: Permission.P_MASK_ACTION_LOG
        },
        {icon: 'mdi-email', name: 'アイテム付与', to: {name: 'grant-item'}, permission: Permission.P_MASK_GRANT},
        {
          icon: 'mdi-alert-circle-outline',
          name: 'アイテム没収',
          to: {name: 'confiscate-item'},
          permission: Permission.P_MASK_CONFISCATE
        },
        {icon: 'mdi-alert-circle-outline', name: 'GDPR', to: {name: 'gdpr'}, permission: Permission.P_MASK_GDPR},
        {
          icon: 'mdi-alert-circle-outline',
          name: 'ユーザーデータ削除',
          to: {name: 'user-delete'},
          permission: Permission.P_MASK_GDPR
        },
        {
          icon: 'mdi-pistol',
          name: 'BANツール',
          to: {name: 'ban'},
          permission: Permission.P_MASK_BAN
        },
      ],
      menuMaster: [
        {
          icon: 'mdi-file-refresh',
          name: 'マスター更新',
          to: {name: 'master-update'},
          permission: Permission.P_MASK_MASTER_UPDATE
        },
        {
          icon: 'mdi-file-send',
          name: 'マスター同期',
          to: {name: 'master-sync'},
          permission: Permission.P_MASK_MASTER_SYNC
        },
        {
          icon: 'mdi-book-open',
          name: 'バージョン操作',
          to: {name: 'version-control'},
          permission: Permission.P_MASK_VERSION_CONTROL
        },
        {
          icon: 'mdi-book-open',
          name: 'プロモーションコード',
          to: {name: 'promotion-code'},
          permission: Permission.P_MASK_PROMOTION_CODE
        },
      ],
      menuOperation: [
        // {icon: 'mdi-home', name: 'ホーム', to: {name: 'home'}, permission: null},
        {
          icon: 'mdi-file-search',
          name: 'マスター確認',
          to: {name: 'master-view'},
          permission: Permission.P_MASK_MASTER_VIEW
        },
        {
          icon: 'mdi-calendar-month',
          name: 'イベントスケジュール',
          to: {name: 'schedule'},
          permission: Permission.P_MASK_SCHEDULE
        },
        {
          icon: 'mdi-database-cog-outline',
          name: 'マッチング確認',
          to: {name: 'matching-view'},
          permission: Permission.P_MASK_SCHEDULE
        },
        {
          icon: 'mdi-receipt-text-check',
          name: 'レシート検証',
          to: {name: 'receipt'},
          permission: Permission.P_MASK_RECEIPT
        },
        {
          icon: 'mdi-database-cog-outline',
          name: 'Redis管理',
          to: {name: 'redis-manage'},
          permission: Permission.P_MASK_REDIS_MANAGE
        },
        {
          icon: 'mdi-database-cog',
          name: 'Memcache管理',
          to: {name: 'memcache-manage'},
          permission: Permission.P_MASK_MEMCACHE_MANAGE
        },
      ],
      menuTool: [
        // {icon: 'mdi-home', name: 'ホーム', to: {name: 'home'}, permission: null},
        {
          icon: 'mdi-folder-account',
          name: 'ツールアカウント管理',
          to: {name: 'account-manage'},
          permission: Permission.P_MASK_ACCOUNT_MANAGE
        },
        {icon: 'mdi-math-log', name: 'ツールログ確認', to: {name: 'tool-log'}, permission: Permission.P_MASK_TOOL_LOG},
      ],
      menuDebug: [
        {icon: 'mdi-laptop', name: 'デバッグ機能', to: {name: 'debug'}, permission: Permission.P_MASK_DEBUG},
        {icon: 'mdi-math-log', name: 'デバッグログ', to: {name: 'debug-log'}, permission: Permission.P_MASK_DEBUG_LOG},
      ],
    }
  },
  created() {
    this.getMeAction().then(data => {
      if (data.status === 401) {
        this.$router.push({path: '/tools/login'})
      }
    })
    this.getEnvAction()
    this.auth = new Auth.Auth(this.me);
  },
  computed: {
    ...mapGetters('auth', [
      'isLogin',
      'me',
    ]),
    ...mapGetters('env', [
      'environment',
    ]),
    headerColor() {
      switch (this.environment.environment) {
        case "local":
          return "bg-teal darken-1"
        case "development":
          return "bg-deep-purple accent-4"
        case "qa":
          if (this.environment.namespace === "dev-qa") {
            return "bg-red darken-3"
          }
          return "bg-light-blue darken-3"
        case "staging":
          if (this.environment.namespace !== "") {
            return "bg-blue-grey darken-3"
          }
          return "bg-brown darken-2"
        case "production":
          if (this.environment.namespace === "feature") {
            return "bg-blue-grey darken-3"
          }
          if (this.environment.namespace === "review") {
            return "bg-blue-grey darken-3"
          }
          return "bg-amber darken-4"
        default:
          return ""
      }
    },
    headerName() {
      switch (this.environment.environment) {
        case "local":
          return "ローカル"
        case "development":
          return "開発"
        case "qa":
          if (this.environment.namespace === "dev-qa") {
            return "DevQA"
          }
          return "QA"
        case "staging":
          if (this.environment.namespace !== "") {
            return "追加環境(" + this.environment.namespace + ")"
          }
          return "ステージング"
        case "production":
          if (this.environment.namespace === "feature") {
            return "Feature"
          }
          if (this.environment.namespace === "review") {
            return "レビュー"
          }
          return "本番"
        default:
          return "環境不明"
      }
    },
  },
  methods: {
    TOOL_CATEGORY_DEBUG() {
      return TOOL_CATEGORY_DEBUG
    },
    TOOL_CATEGORY_TOOL() {
      return TOOL_CATEGORY_TOOL
    },
    TOOL_CATEGORY_OPERATION() {
      return TOOL_CATEGORY_OPERATION
    },
    TOOL_CATEGORY_DATA() {
      return TOOL_CATEGORY_DATA
    },
    TOOL_CATEGORY_USER() {
      return TOOL_CATEGORY_USER
    },
    ...mapMutations('auth', {
      logoutMutation: 'logout',
    }),
    ...mapActions('auth', {
      getMeAction: 'getMe',
    }),
    ...mapActions('env', {
      getEnvAction: 'getEnv',
    }),
    ...mapMutations('snackbar', {
      snackbarInfo: 'info'
    }),
    logout() {
      this.logoutMutation();

      this.snackbarInfo("ログアウトしました")

      // ログインページに移動する
      this.$router.push({name: 'login'})
    }
  },
}
</script>

<style>
</style>