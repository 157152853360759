const state = {
  snackbar: {
    message: null,
    color: null,
    timeout: 2000,
    isDraw: false
  }
};

const mutations = {
  success(state, message) {
    state.snackbar.message = message;
    state.snackbar.color = 'success';
    state.snackbar.isDraw = true
  },
  error(state, message) {
    state.snackbar.message = message;
    state.snackbar.color = 'error';
    state.snackbar.isDraw = true
  },
  info(state, message) {
    state.snackbar.message = message;
    state.snackbar.color = 'info';
    state.snackbar.isDraw = true
  }
};

const getters = {
  snackbar(state) {
    return state.snackbar
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
