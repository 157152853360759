export const
    TOOL_CATEGORY_USER = "ユーザー",
    TOOL_CATEGORY_DATA = "データ更新",
    TOOL_CATEGORY_OPERATION = "運営情報",
    TOOL_CATEGORY_TOOL = "ツール",
    TOOL_CATEGORY_DEBUG = "デバッグ";

export default {
    TOOL_CATEGORY_USER: TOOL_CATEGORY_USER,
    TOOL_CATEGORY_DATA: TOOL_CATEGORY_DATA,
    TOOL_CATEGORY_OPERATION: TOOL_CATEGORY_OPERATION,
    TOOL_CATEGORY_TOOL: TOOL_CATEGORY_TOOL,
    TOOL_CATEGORY_DEBUG: TOOL_CATEGORY_DEBUG,
}