export const
    P_MASK_USER_SEARCH = 1 << 0,
    P_MASK_RESTORE_DATA = 1 << 1,
    P_MASK_USER_EDIT = 1 << 2,
    P_MASK_GDPR = 1 << 3,
    P_MASK_GRANT = 1 << 4,
    P_MASK_MASTER_UPDATE = 1 << 5,
    P_MASK_MASTER_SYNC = 1 << 6,
    P_MASK_MASTER_VIEW = 1 << 7,
    P_MASK_SCHEDULE = 1 << 8,
    P_MASK_ACTION_LOG = 1 << 9,
    P_MASK_ACCOUNT_MANAGE = 1 << 10,
    P_MASK_DEBUG = 1 << 11,
    P_MASK_TOOL_LOG = 1 << 12,
    P_MASK_RECEIPT = 1 << 13,
    P_MASK_REDIS_MANAGE = 1 << 14,
    P_MASK_MEMCACHE_MANAGE = 1 << 15,
    P_MASK_VERSION_CONTROL = 1 << 16,
    P_MASK_PROMOTION_CODE = 1 << 17,
    P_MASK_DEBUG_LOG = 1 << 18,
    P_MASK_BAN = 1 << 19,
    P_MASK_CONFISCATE = 1 << 20;
export default {
    P_MASK_USER_SEARCH: P_MASK_USER_SEARCH,
    P_MASK_RESTORE_DATA: P_MASK_RESTORE_DATA,
    P_MASK_USER_EDIT: P_MASK_USER_EDIT,
    P_MASK_GDPR: P_MASK_GDPR,
    P_MASK_GRANT: P_MASK_GRANT,
    P_MASK_MASTER_UPDATE: P_MASK_MASTER_UPDATE,
    P_MASK_MASTER_SYNC: P_MASK_MASTER_SYNC,
    P_MASK_MASTER_VIEW: P_MASK_MASTER_VIEW,
    P_MASK_SCHEDULE: P_MASK_SCHEDULE,
    P_MASK_ACTION_LOG: P_MASK_ACTION_LOG,
    P_MASK_ACCOUNT_MANAGE: P_MASK_ACCOUNT_MANAGE,
    P_MASK_DEBUG: P_MASK_DEBUG,
    P_MASK_TOOL_LOG: P_MASK_TOOL_LOG,
    P_MASK_RECEIPT: P_MASK_RECEIPT,
    P_MASK_REDIS_MANAGE: P_MASK_REDIS_MANAGE,
    P_MASK_MEMCACHE_MANAGE: P_MASK_MEMCACHE_MANAGE,
    P_MASK_VERSION_CONTROL: P_MASK_VERSION_CONTROL,
    P_MASK_PROMOTION_CODE: P_MASK_PROMOTION_CODE,
    P_MASK_DEBUG_LOG: P_MASK_DEBUG_LOG,
    P_MASK_BAN: P_MASK_BAN,
    P_MASK_CONFISCATE: P_MASK_CONFISCATE,
}