import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import {createVuetify} from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'dark'
  },
  icons: {
    iconfont: 'mdi',
  },
});
