import createPersistedState from "vuex-persistedstate";
import auth from './auth'
import user from './user'
import env from './env'
import snackbar from './snackbar'
import error from './error'
import {createStore} from "vuex";

export default createStore({
  modules: {
    auth,
    user,
    env,
    snackbar,
    error
  },
  plugins: [createPersistedState({
    key: 'greenSupportTools',
    paths: ['auth.token'],
    storage: window.sessionStorage
  })]
})