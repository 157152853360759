<template>
  <v-app>
    <snack-bar></snack-bar>
    <div v-if="isLogin">
      <tool-menu v-if="isReloadFlg"/>
    </div>
    <v-main>
      <div id="top">
        <router-view @reload="reloadToolMenu"></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";
import SnackBar from "./components/SnackBar";
import ToolMenu from "./components/ToolMenu";

export default {
  name: 'App',
  components: {
    ToolMenu,
    SnackBar,
  },
  data: () => ({
    isReloadFlg: true,
  }),
  created() {
  },
  computed: {
    ...mapGetters('auth', [
      'isLogin',
    ])
  },
  methods: {
    reloadToolMenu() {
      this.isReloadFlg = false
      this.$nextTick(() => {
        this.isReloadFlg = true
      })
    }
  }
};
</script>

<style>
.customize-table {
  --easy-table-border: 1px solid #1e1e1e;
  --easy-table-row-border: 1px solid #383838;

  --easy-table-header-font-color: #8c8c8c;
  --easy-table-header-background-color: #1e1e1e;

  --easy-table-body-even-row-font-color: #eeeeee;
  --easy-table-body-even-row-background-color: #1e1e1e;

  --easy-table-body-row-font-color: #eeeeee;
  --easy-table-body-row-background-color: #1e1e1e;

  --easy-table-body-row-hover-font-color: #eeeeee;
  --easy-table-body-row-hover-background-color: #1e1e1e;

  --easy-table-footer-font-color: #eeeeee;
  --easy-table-footer-background-color: #1e1e1e;
}
</style>
