import axios from 'axios';

const state = {
  token: null,
  me: {
    id: null,
    name: null,
    permission: null,
  }
};

const getters = {
  isLogin(state) {
    return state.token !== null;
  },
  me(state) {
    return state.me;
  }
};

const mutations = {
  login(state, token) {
    state.token = token;
  },
  logout(state) {
    state.token = null;
    state.me.id = null;
    state.me.name = null;
    state.me.permission = null;
  },
  me(state, payload) {
    state.me.id = payload.id;
    state.me.name = payload.name;
    state.me.permission = payload.permission;
  }
};

const actions = {
  async login({commit}, params) {
    return await axios.post('/api/login', params).then(response => {
      const token = response.data.token;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      commit('login', token);
      return response;
    }).catch(error => {
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async register({commit}, params) {
    return await axios.post('/api/tool-user/register', params).then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async update({commit}, params) {
    return await axios.post('/api/tool-user/update', params).then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async updatePassword({commit}, params) {
    return await axios.post('/api/tool-user/update/password', params).then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async delete({commit}, params) {
    return await axios.delete('/api/tool-user?ID=' + params.ID).then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async getList({commit}) {
    return await axios.get('/api/tool-user').then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async getMe({commit}) {
    if (this.me != null) {
      // 取得済みの場合は取得しない
      return null
    }
    return await axios.get('/api/me').then(response => {
      commit('me', response.data)
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('logout');
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  // 認証情報クリア
  clear({commit}) {
    commit('logout');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}