import axios from 'axios';

const state = {
  env: {
    environment: null,
    namespace: null
  }
};

const getters = {
  environment(state) {
    return state.env;
  }
};

const mutations = {
  environment(state, payload) {
    state.env.environment = payload.environment;
    state.env.namespace = payload.namespace;
  }
};

const actions = {
  async getEnv({commit}) {
    if (this.environment != null) {
      // 取得済みの場合は取得しない
      return null
    }
    return await axios.get('/api/env').then(response => {
      commit('environment', response.data)
      return response;
    }).catch((error) => {
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}