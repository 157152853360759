import axios from 'axios';

const state = {
  user: {},
  payments: [],
  present: [],
};

const getters = {
  user(state) {
    return state.user;
  },
  payment(state) {
    return state.payments;
  },
  present(state) {
    return state.presents;
  },
};

const mutations = {
  user(state, payload) {
    state.user = payload;
  },
  payment(state, payload) {
    state.payments = payload;
  },
  present(state, payload) {
    state.presents = payload;
  },
};

const actions = {
  async getUser({commit}, params) {
    return await axios.get('/api/user/personal/info', {params}).then(response => {
      commit('user', response.data)
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('auth/logout', null, {root: true});
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async getPayment({commit}, params) {
    return await axios.get('/api/user/payment/info', {params}).then(response => {
      commit('payment', response.data.paymentSlice)
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('auth/logout', null, {root: true});
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async getPresent({commit}, params) {
    return await axios.get('/api/user/present/info', {params}).then(response => {
      commit('present', response.data.userPresentSlice)
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('auth/logout', null, {root: true});
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  },
  async putUserStatus({commit}, params) {
    return await axios.put('/api/user/status', params).then(response => {
      return response;
    }).catch((error) => {
      if (error.response.status === 401) {
        commit('auth/logout', null, {root: true});
      }
      commit('error/response', error.response, {root: true});
      return error.response;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}