const state = {
  response: null
};

const mutations = {
  response(state, response) {
    state.response = response;
  }
};

const getters = {
  response(state) {
    return state.response
  }
};

const actions = {
  clear({commit}) {
    commit('response', null);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
