import {createApp} from 'vue'
import axios from 'axios';
import router from './router';
import store from './store';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import camelcaseKeys from 'camelcase-keys';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

// Vue.config.productionTip = false

// セッション取得
const ss = sessionStorage.getItem('greenSupportTools');
if (ss) {
  const storageData = JSON.parse(ss);
  if (storageData.auth.token) {
    axios.defaults.headers.common.Authorization = 'Bearer ' + storageData.auth.token;
  }
}

// レスポンスJSONのキーをキャメルケースに変換するようにインターセプタをしかけておく
// （ただし、エラー時は生レスポンスをそのまま表示したいので正常応答とする）
axios.interceptors.response.use((response) => {
  const contentType = response.headers['content-type'];
  if (contentType.indexOf('application/json') !== -1 && response.status === 200) {
    response.data = camelcaseKeys(response.data, {deep: true});
  }
  return response;
}, error => {
  if (error.response.status === 401) {
    // セッション切れ
    store.commit('auth/logout');
    store.commit('snackbar/error', "セッションが切れました")
    router.push({name: 'login'})
    return
  }
  return Promise.reject(error)
});

const app = createApp(App)

app.use(router)
app.use(vuetify)
app.use(store)

app.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);
app.component('EasyDataTable', Vue3EasyDataTable);

app.mount('#app')
