<template>
  <v-snackbar class="d-flex align-start" v-model="snackbar.isDraw" :timeout="snackbar.timeout" :color="snackbar.color"
              location="true">
    {{ snackbar.message }}
  </v-snackbar>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'SnackBar',
  computed: {
    ...mapGetters('snackbar', {
      snackbar: 'snackbar'
    })
  }
}
</script>