export class Auth {

  constructor(me) {
    this.me = me
  }

  getNameOneCharacter() {
    if (this.me.name === null) {
      return ""
    }
    return this.me.name.slice(0, 1)
  }

  isOwnedPermission(permission) {
    return (permission === null || ((this.me.permission & permission) === permission))
  }
}
